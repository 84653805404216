import React, {FC} from 'react';
import Typography from '@mui/material/Typography';
import {GatewayDisplayNames} from '../../../@types/GatewayDisplayNames';
import ShuttleDescription from './ShuttleDescription';

interface ConnectGatewayDescriptionProps {
  gatewayName: GatewayDisplayNames;
}

interface ConnectGatewayMapConfig {
  description: React.ReactNode;
}

const ConnectGatewayDescription: FC<
  React.PropsWithChildren<ConnectGatewayDescriptionProps>
> = ({gatewayName}) => {
  const connectGatewayMap: Record<
    GatewayDisplayNames,
    ConnectGatewayMapConfig
  > = {
    [GatewayDisplayNames.STRIPE]: {
      description: (
        <Typography
          sx={{
            display: 'inline-flex',
            fontWeight: 500,
            width: '100%',
            alignSelf: 'flex-start',
          }}
        >
          After you click Connect, you will be redirected to STRIPE to log in,
          in order to provision Hands In Sandbox access via STRIPE&apos;s
          connect platform. Once completed, you will be securely redirected back
          to Hands In.
        </Typography>
      ),
    },
    [GatewayDisplayNames.SQUARE]: {
      description: (
        <Typography
          sx={{
            display: 'inline-flex',
            fontWeight: 500,
            width: '100%',
            alignSelf: 'flex-start',
          }}
        >
          After you click Connect, you will be redirected to SQUARE to log in,
          in order to provision Hands In Sandbox access via SQUARE&apos;s
          connect platform. Once completed, you will be securely redirected back
          to Hands In. Note: you must have your square dashboard open in another
          tab to be redirected successfully.
        </Typography>
      ),
    },
    [GatewayDisplayNames.AYDEN]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.BRAINTREE]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.BRIDGERPAY]: {
      description: (
        <Typography
          sx={{
            display: 'inline-flex',
            fontWeight: 500,
            width: '100%',
            alignSelf: 'flex-start',
          }}
        >
          Connect to Bridger Pay by setting a display name for this connection
          and providing your Bridger Pay merchant account credentials
        </Typography>
      ),
    },
    [GatewayDisplayNames.BSPAYONE]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.CARDCONNECT]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.CHECKOUT]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.ECOMMPAY]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.GLOBALPAYMENTS]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.NMI]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.OPAYO]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.PAYSAFE]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.PAYTOMORROW]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.SHIFT4]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.TRUSTPAYMENTS]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    // for gateways that redirect:
    [GatewayDisplayNames.AUTHORIZENET]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.MOLLIE]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.PAYPAL]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
    [GatewayDisplayNames.QUICKBOOKS]: {
      description: <ShuttleDescription isRedirect gatewayName={gatewayName} />,
    },
  };

  const config = connectGatewayMap[gatewayName];

  return config ? config.description : null;
};

export default ConnectGatewayDescription;
