import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, {FC, useState} from 'react';
import {useCreatePaymentGatewayRedirectUrl} from '@local/frontend/libs/trpc/trpc';
import {
  Gateway,
  PublicConnectDTO,
} from '@local/backend/@types/updated-api-types/gateways/Gateway';
import {useNotification} from '@local/frontend/hooks/useNotification';
import LoadingButton from '@local/frontend/components/atoms/buttons/LoadingButton';
import ShuttleConfiguration from '@local/frontend/pages/dashboard/connect/components/configurations/ShuttleConfiguration';
import BridgerPayConfiguration from '@local/frontend/pages/dashboard/connect/components/configurations/BridgerPayConfiguration';
import {GatewayDisplayNames} from '../../@types/GatewayDisplayNames';
import ConnectGatewayDescription from './components/ConnectGatewayDescription';

interface ConnectGatewayModalProps {
  displayName: GatewayDisplayNames;
  gateway: Gateway;
  icon: React.JSX.Element;
}

const ConnectGatewayModal: FC<
  React.PropsWithChildren<ConnectGatewayModalProps>
> = ({displayName, icon, gateway}) => {
  const {open: openNotification} = useNotification();

  const createConnectionMutation = useCreatePaymentGatewayRedirectUrl({
    onSuccess: redirectUrl => {
      window.location.href = redirectUrl;
    },
    onError: err => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data.detail ??
          `Failed to connect to ${gateway}!`,
        severity: 'error',
      });
    },
  });

  const onCreateConnectionFormSubmit = () => {
    createConnectionMutation.mutate({
      gateway,
    });
  };

  const getGatewayProviderName = (gatewayProvider: Gateway) => {
    switch (gatewayProvider) {
      case Gateway.SHUTTLE:
        return 'Shuttle Global';
      case Gateway.STRIPE:
      case Gateway.SQUARE:
      default:
        return 'Hands In';
    }
  };

  const [containerRef, setContainerRef] = useState<Element | undefined>();
  const [connectRecord, setConnectRecord] = useState<
    PublicConnectDTO | undefined
  >(undefined);

  return (
    <Stack spacing={2} alignItems="center">
      <Box
        sx={{
          p: 4,
          width: '300px',
          minHeight: '100px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icon}
      </Box>
      <Typography
        sx={{
          display: 'inline-flex',
          fontWeight: 500,
          width: '100%',
          alignSelf: 'flex-start',
        }}
        justifyContent="center"
        component="span"
      >
        Provided By {getGatewayProviderName(gateway)}
      </Typography>
      <Typography
        sx={{
          display: 'inline-flex',
          fontWeight: 600,
          width: '100%',
          alignSelf: 'flex-start',
        }}
      >
        What happens next?
      </Typography>
      <ConnectGatewayDescription gatewayName={displayName} />

      {containerRef ? (
        <Box sx={{width: '100%'}}>
          {gateway === Gateway.SHUTTLE && (
            <ShuttleConfiguration
              actionAreaRef={containerRef}
              paymentGateway={gateway}
              gatewayDisplayName={displayName}
              connectRecord={connectRecord}
              setConnectRecord={setConnectRecord}
            />
          )}
          {gateway === Gateway.BRIDGERPAY && (
            <BridgerPayConfiguration
              actionAreaRef={containerRef}
              paymentGateway={gateway}
              gatewayDisplayName={displayName}
              connectRecord={connectRecord}
              setConnectRecord={setConnectRecord}
            />
          )}
        </Box>
      ) : (
        <LoadingButton
          sx={{marginLeft: 'auto !important'}}
          onClick={onCreateConnectionFormSubmit}
          loading={createConnectionMutation.isLoading}
          variant="contained"
        >
          Connect {displayName}
        </LoadingButton>
      )}
      {(gateway === Gateway.SHUTTLE || gateway === Gateway.BRIDGERPAY) && (
        <Box
          sx={{marginLeft: 'auto !important'}}
          ref={ref => {
            setContainerRef(ref as Element);
          }}
        />
      )}
    </Stack>
  );
};

export default ConnectGatewayModal;
