import type {FC} from 'react';
import React, {useCallback, useMemo, useState} from 'react';
import type {
  CellClickedEvent,
  ColDef,
  FilterChangedEvent,
  FilterModel,
  GridApi,
  GridReadyEvent,
} from '@ag-grid-community/core';
import {MoneyUtils} from '@handsin/money';
import {useNavigate} from 'react-router-dom';
import {CsvExportModule} from '@ag-grid-community/csv-export';
import {Stack} from '@mui/material';
import Table from '@local/frontend/components/tables/Table';
import TableHeader from '@local/frontend/components/tables/TableHeader';
import dayjs from 'dayjs';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {PaymentItem} from '@local/backend/db/payments';
import {useFindPayments} from '@local/frontend/libs/trpc/trpc';
import CustomerCell from '../../../../components/tables/cells/CustomerCell';
import {DateCell} from '../../../../components/tables/cells/DateCell';
import {IdCell} from '../../../../components/tables/cells/IdCell';
import {PaymentStatusCell} from '../../../../components/tables/cells/PaymentStatusCell';
import {MoneyCell} from '../../../../components/tables/cells/MoneyCell';
import {ProcessorCell} from '../../../../components/tables/cells/PaymentMethodCell';
import PaymentWidgets from './ListPayments.Widgets';

const modules = [ClientSideRowModelModule, CsvExportModule];

const NewPaymentsTable: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();

  const [gridApi, setGridApi] = useState<GridApi | undefined>();

  const [tableFilterModel, setTableFilterModel] = useState<
    FilterModel | undefined
  >(undefined);
  const [searchValue, setSearchValue] = useState<string>('');
  const [rangeStartDate, setRangeStartDate] = useState<Date | undefined>(
    dayjs().subtract(1, 'month').startOf('day').toDate()
  );
  const [rangeEndDate, setRangeEndDate] = useState<Date | undefined>(undefined);

  const globalFilterModel = useMemo<FilterModel>(
    () =>
      searchValue
        ? {
            id: {
              type: 'equals',
              filterType: 'text',
              filter: searchValue,
            },
          }
        : {
            createdAt:
              rangeStartDate || rangeStartDate
                ? {
                    type: 'inRange',
                    dateFrom: rangeStartDate?.toISOString(),
                    dateTo: rangeEndDate?.toISOString(),
                  }
                : undefined,
            ...tableFilterModel,
          },
    [searchValue, rangeEndDate, rangeStartDate, tableFilterModel]
  );

  const {
    data: paymentData,
    isLoading: isPaymentDataLoading,
    isError,
    error,
    isFetching,
  } = useFindPayments(
    {
      filter: globalFilterModel,
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const columnDefs = useMemo<ColDef<PaymentItem>[]>(
    () => [
      {
        minWidth: 100,
        type: 'rightAligned',
        headerName: 'Amount',
        valueFormatter: ({data}) =>
          data?.totalMoney ? MoneyUtils.formatMoney(data?.totalMoney) : '',
        field: 'totalMoney',
        cellRenderer: MoneyCell,
      },
      {
        minWidth: 100,
        headerName: 'Status',
        field: 'status',
        cellRenderer: PaymentStatusCell,
      },
      {
        headerName: 'Payment method',
        field: 'gatewayName',
        cellRenderer: ProcessorCell,
      },
      {
        minWidth: 180,
        headerName: 'Reference ID',
        field: 'referenceId',
        cellRenderer: IdCell,
        filter: true,
        filterParams: {
          filterOptions: ['equals', 'contains'],
          filterPlaceholder: 'Filter by reference ID',
        },
      },
      {
        flex: 2,
        minWidth: 200,
        headerName: 'Customer',
        field: 'customerId',
        filter: true,
        filterParams: {
          filterOptions: ['equals'],
          filterPlaceholder: 'Filter by customer ID',
        },
        cellRenderer: CustomerCell,
      },
      {
        flex: 2,
        minWidth: 200,
        headerName: 'Description',
        field: 'description',
        filter: true,
        filterParams: {
          filterOptions: ['equals', 'contains'],
          filterPlaceholder: 'Filter by description',
        },
      },
      {
        minWidth: 250,
        headerName: 'Date',
        field: 'createdAt',
        cellDataType: 'date',
        type: 'rightAligned',
        cellStyle: {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        },
        valueFormatter: ({data}) =>
          data?.createdAt ? dayjs(data.createdAt).toISOString() : '',
        cellRenderer: DateCell,
      },
    ],
    []
  );

  const onCellClicked = useCallback((e: CellClickedEvent) => {
    if (!e.column.isPinned()) navigate(`/dashboard/payments/${e.data?.id}`);
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
  }, []);

  const handleFilterChange = useCallback((params: FilterChangedEvent) => {
    const changedFilterModel = params.api.getFilterModel();
    setTableFilterModel(changedFilterModel);
  }, []);

  const onSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const onRangeChange = (startDate: Date | null, endDate: Date | null) => {
    setRangeStartDate(startDate ?? undefined);
    setRangeEndDate(endDate ?? undefined);
  };

  return (
    <Stack spacing={1} width="100%" height="100%">
      <TableHeader
        isFetching={isFetching}
        searchValue={searchValue}
        rangeStartDate={rangeStartDate ? new Date(rangeStartDate) : undefined}
        rangeEndDate={rangeEndDate ? new Date(rangeEndDate) : undefined}
        onSearchChange={onSearchChange}
        onRangeChange={onRangeChange}
        gridApi={gridApi}
        resultsFound={paymentData?.length ?? 0}
        searchPlaceholder="Search for payment ID"
        exportFilename="payments"
      />

      <PaymentWidgets
        paymentData={paymentData ?? []}
        isLoading={isPaymentDataLoading}
      />

      <Table<PaymentItem>
        rowData={paymentData ?? []}
        isLoading={isPaymentDataLoading}
        columnDefs={columnDefs}
        modules={modules}
        rowModelType="clientSide"
        onCellClicked={onCellClicked}
        onFilterChanged={handleFilterChange}
        onGridReady={onGridReady}
      />
      {isError && <div>Error: {JSON.stringify(error)}</div>}
    </Stack>
  );
};

export default NewPaymentsTable;
